import CodeInput from "@components/CodeInput/CodeInput";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { choresService } from "@services/Chores/chores.service";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

const Home: FunctionComponent = () => {
  const navigate = useNavigate();

  const handleOnComplete = (code: string) => {
    navigate(code);
  };

  const handleNavigation = async () => {
    const code = choresService.generateCode();
    try {
      await choresService.create(Number(code));
    } catch (e) {
      console.error(e);
    } finally {
      navigate(`${code}/edit`);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "20px 0",
        }}
      >
        <Typography variant="h3">Chores</Typography>

        <Typography variant="h5" sx={{ margin: 2 }}>
          Enter code
        </Typography>

        <CodeInput onComplete={handleOnComplete} />

        <Typography variant="h5" sx={{ margin: 2 }}>
          or
        </Typography>

        <Button onClick={handleNavigation} variant="contained">
          Make a list!
        </Button>
      </Box>
    </Box>
  );
};

export default Home;
