import { useSnackBar } from "@contexts/Snackbar.context";
import { ListProps } from "@interfaces/list.interface";
import { Box, Typography } from "@mui/material";
import { FunctionComponent, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { getListItemStyles, reorder } from "./shared/functions";

const ViewList: FunctionComponent<ListProps> = ({
  chores,
  onChoresChange,
}) => {
  const { showSnackBar } = useSnackBar();
  const [selected, setSelected] = useState<string>("");

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list, delete chore
    if (!result.destination) {
      return;
    }

    const reorderedChores = reorder(
      chores,
      result.source.index,
      result.destination.index
    );

    onChoresChange(reorderedChores);
  };

  const handleOnClick = (id: string) => {
    if (selected === id) {
      return handleOnToggleComplete(id);
    }
    setSelected(id);
  };

  const handleOnToggleComplete = (id: string) => {
    let newChores = chores.map((chore) => {
      if (chore.id === id) {
        if (!chore.completed) {
          showSnackBar("Chore completed!", "success");
        }
        chore.completed = !chore.completed;
      }
      return chore;
    });

    // Find changed chore
    const changedChore = newChores.find((chore) => chore.id === id);

    // If changed chore is completed, reorder chores
    if (changedChore?.completed) {
      // Reorder chores so completed chores are at the bottom
      newChores = reorder(
        newChores,
        newChores.findIndex((chore) => chore.id === id),
        newChores.length - 1
      );
    }

    onChoresChange(newChores);
  };

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {chores.map((chore, index) => (
                <Draggable key={chore.id} draggableId={chore.id} index={index}>
                  {(provided, snapshot) => {
                    const isChoreSelected = selected === chore.id;
                    const isChoreCompleted = chore.completed;
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getListItemStyles(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          isChoreSelected,
                          isChoreCompleted
                        )}
                        onClick={() => handleOnClick(chore.id)}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "2em",
                            }}
                          >
                            {chore.name}
                          </Box>
                        </Box>
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
              {chores.length === 0 && (
                <Box
                  sx={{
                    color: "salmon",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="caption" sx={{ padding: "1em" }}>
                    No chores! Huzzah!
                  </Typography>
                </Box>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default ViewList;
