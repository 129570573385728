import { Chore } from "@/interfaces/chore.interface";
import ViewList from "@components/Lists/ViewList";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import Confetti from "react-confetti";
import { choresService } from "@services/Chores/chores.service";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Category } from "@/interfaces/category.interface";
import { categoryService } from "@/services/Category/category.service";

const View: FunctionComponent = () => {
  const [chores, setChores] = useState<Chore[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [listId, setListId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [confetti, setConfetti] = useState<boolean>(false);

  const { code } = useParams();

  useEffect(() => {
    if (code) {
      try {
        setLoading(true);
        getData(Number(code));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (listId) {
      try {
        setLoading(true);
        choresService.update(
          listId,
          Number(code),
          chores,
          categories.map(({ id }) => id)
        );
      } catch (e) {
        console.error(e);
      } finally {
        setProgress(() => calculateProgress());
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chores]);

  // If progress is 100% show confetti
  useEffect(() => {
    setConfetti(progress === 100);
    // after 5 seconds turn confetti off
    setTimeout(() => {
      setConfetti(false);
    }, 5000);
  }, [progress]);

  const calculateProgress = () => {
    const amountCompleted = chores.reduce(
      (completed, chore) => (chore.completed ? completed + 1 : completed),
      0
    );
    const progress = Math.floor((amountCompleted / chores.length) * 100);

    return progress;
  };

  const getData = async (code: number): Promise<void> => {
    const { id, chores, categoryIds } = await choresService.get(code);
    setChores(chores);
    setListId(id);

    const categories = await categoryService.get(categoryIds);
    setCategories(categories);

    choresService.subscribe(id, (newData) => {
      const { chores } = newData;
      setChores(chores);
    });
  };

  const handleOnListChange = (chores: Chore[]) => {
    setChores(chores);
  };

  if (!listId || !code || loading) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Confetti numberOfPieces={800} recycle={confetti}></Confetti>

      <Box
        sx={{
          height: "100%",
          width: "100%",
          padding: "1em",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ViewList
          chores={chores}
          categories={categories}
          onChoresChange={handleOnListChange}
        />
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      </Box>
    </>
  );
};

export default View;
