import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import pink from "@mui/material/colors/pink";
import { Box } from "@mui/system";
import Edit from "@views/Edit/Edit";
import Home from "@views/Home/Home";
import View from "@views/View/View";
import { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";

const App: FunctionComponent = () => {
  const theme = createTheme({
    typography: {
      fontFamily: "Roboto, Arial",
      h3: {
        fontFamily: "Oswald, Roboto",
        fontWeight: 800,
        color: pink[400],
      },
      h5: {
        fontFamily: "Oswald, Roboto",
        fontWeight: 800,
        color: pink[400],
      },
    },
    palette: {
      primary: pink,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Box>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path=":code" element={<View />} />
          <Route path=":code/edit" element={<Edit />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Box>
    </ThemeProvider>
  );
};

export default App;
