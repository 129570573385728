import { Category } from "@/interfaces/category.interface";
import { FirebaseService } from "@services/Firebase/firebase.service";
import BaseService from "../Base/base.service";

class CategoryService extends BaseService {
  private collection = "category";
  private firebaseService: FirebaseService;

  constructor() {
    super();
    this.firebaseService = new FirebaseService(this.collection);
  }

  /**
   * Creates category
   * @param {string} categoryId
   * @param {string} categoryName
   * @returns {void}
   */
  public async create(
    categoryId: string,
    categoryName: string
  ): Promise<Category> {
    const newCategory: Category = { id: categoryId, name: categoryName };
    const documentData = await this.firebaseService.create(
      newCategory.id,
      newCategory
    );

    if (!documentData) {
      throw new Error("Failed to create category");
    }

    const { id, name } = documentData;
    return { id, name };
  }

  /**
   * Fetches a category by id
   * @param {string} id
   * @returns {Promise<Category[]>}
   */
  public async get(categoryIds: string[]): Promise<Category[]> {
    const categoryPromises = categoryIds.map((categoryId) =>
      this.firebaseService.getByProperty("id", categoryId)
    );

    const categoryResponse = await Promise.all(categoryPromises);

    if (!categoryResponse) {
      throw new Error(`No categories found with ids: ${categoryIds}`);
    }

    const categories: Category[] = categoryResponse
      .map(([document]) => document)
      .map(({ id, name }) => ({ id, name }));

    return categories;
  }

  /**
   * Updates a category by id
   * @param {string} categoryId
   * @param {string} name
   * @returns {Promise<Category>}
   */
  public async update(id: string, name: string): Promise<void> {
    if (!this.validateCategoryName(name)) {
      throw new Error("Invalid category name");
    }

    const document: Category = { id, name };
    return await this.firebaseService.update(id, document);
  }
}

export const categoryService = new CategoryService();
