/**
 * Reorders list
 * @param {T[]} list
 * @param {number} startIndex
 * @param {number} endIndex
 * @returns {any}
 */
function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

/**
 * Get CSS for list items
 * @param {boolean} isDragging
 * @param {any} draggableStyle
 * @param {boolean} isSelected
 * @param {boolean} isCompleted
 * @returns {CSSProperties}
 */
function getListItemStyles(
  isDragging: boolean,
  draggableStyle: any,
  isSelected: boolean,
  isCompleted: boolean
): React.CSSProperties {
  return {
    userSelect: "none",
    padding: "12px",
    marginBottom: "8px",
    width: "350px",

    transition: "all 0.15s",
    background: isDragging ? "lightgreen" : isCompleted ? "green" : "#ff9a9e",
    opacity: isCompleted ? 0.55 : 1,

    borderRadius: "12px",
    color: "white",
    boxShadow: "none",
    ...(isSelected && {
      boxShadow:
        "1px 1px rgba(240, 46, 170, 0.4), 2px 2px rgba(240, 46, 170, 0.3),  3px 3px rgba(240, 46, 170, 0.2)",
    }),

    // styles we need to apply on draggables
    ...draggableStyle,
  };
}

export { reorder, getListItemStyles };
