import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import {
  collection,
  doc,
  DocumentData,
  Firestore,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";

export class FirebaseService {
  private app: FirebaseApp;
  private firestore: Firestore;

  constructor(private collection: string) {
    this.app = initializeApp(this.getCredentials());
    this.firestore = getFirestore();
  }

  /**
   * Creates document in firebase collection
   * @param {string} documentId
   * @param {T} document
   * @returns {Promise<DocumentData>}
   */
  public async create<T>(
    documentId: string,
    document: T
  ): Promise<DocumentData> {
    await setDoc(doc(this.firestore, this.collection, documentId), document);
    const [result] = await this.getByProperty("id", documentId);
    return result;
  }

  /**
   * Updates document in firebase collection
   * @param {string} documentId
   * @param {T} updatedDocument
   * @returns {Promise<void>}
   */
  public async update<T>(
    documentId: string,
    updatedDocument: T
  ): Promise<void> {
    return await setDoc(
      doc(this.firestore, this.collection, documentId),
      updatedDocument
    );
  }

  /**
   * Fetches documents by property
   * @param {string} property
   * @param {any} value
   * @returns {Promise<DocumentData[]>}
   */
  public async getByProperty(
    property: string,
    value: any
  ): Promise<DocumentData[]> {
    const collectionRef = collection(this.firestore, this.collection);
    const documentQuery = query(collectionRef, where(property, "==", value));
    const querySnapshot = await getDocs(documentQuery);

    const result: DocumentData[] = [];

    querySnapshot.forEach(async (doc) => {
      const data = await doc.data();
      result.push(data);
    });

    return result;
  }

  /**
   * Subscribes to changes on documents, calls callback if triggered
   * This can be done without a hacky callback function
   * @param {string} documentId
   * @param {any} callback:(data:any
   * @returns {void}
   */
  public subscribe(documentId: string, callback: (data: any) => void) {
    onSnapshot(doc(this.firestore, this.collection, documentId), (doc) => {
      if (!doc.metadata.hasPendingWrites) {
        const data = doc.data();
        if (data) {
          callback(data);
        }
      }
    });
  }

  /**
   * Fetches data to enable communication with firebase
   * Only safe in combination with firebase app check and well-defined rules
   * @returns {FirebaseOptions}
   */
  private getCredentials(): FirebaseOptions {
    return {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };
  }
}
