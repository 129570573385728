import { ChoreList } from "@interfaces/chore-list.interface";
import { Chore } from "@interfaces/chore.interface";
import { FirebaseService } from "@services/Firebase/firebase.service";
import { v4 } from "uuid";
import BaseService from "../Base/base.service";

class ChoresService extends BaseService {
  private collection = "chores";
  private firebaseService: FirebaseService;

  constructor() {
    super();
    this.firebaseService = new FirebaseService(this.collection);
  }

  /**
   * Creates chore list
   * @param {number} listCode
   * @returns {ChoreList}
   */
  public async create(listCode: number): Promise<ChoreList> {
    const newList = { id: v4(), code: listCode, chores: [], categoryIds: [] };
    const documentData = await this.firebaseService.create(newList.id, newList);
    const { id, code, chores, categoryIds } = documentData;
    return { id, code, chores, categoryIds };
  }

  /**
   * Fetches a chore list by property, in this case by "code"
   * Due to low integrity of data, more then one list could be fetched due to
   * multiple lists with the same code.
   * @param {number} listCode
   * @returns {Promise<ChoreList>}
   */
  public async get(listCode: number): Promise<ChoreList> {
    const [document] = await this.firebaseService.getByProperty(
      "code",
      listCode
    );

    if (!document) {
      throw new Error(`No document found with code: ${listCode}`);
    }

    const { id, code, chores, categoryIds } = document;

    return { id, code, chores, categoryIds };
  }

  /**
   * Updates a list by id
   * @param {string} id
   * @param {number} code
   * @param {Chore[]} chores
   * @param {string[]} categoryIds
   * @returns {Promise<ChoreList>}
   */
  public async update(
    id: string,
    code: number,
    chores: Chore[],
    categoryIds: string[]
  ): Promise<void> {
    if (!this.validateChores(chores)) {
      throw new Error("Invalid chores");
    }

    const document: ChoreList = { id, code, chores, categoryIds };
    return await this.firebaseService.update(id, document);
  }

  /**
   * Subscribes to a list and calls callback on change
   * @param {string} choreListId
   * @param {(data:ChoreList) => void} callback
   * @returns {void}
   */
  public subscribe(choreListId: string, callback: (data: ChoreList) => void) {
    this.firebaseService.subscribe(choreListId, callback);
  }

  /**
   * Validates length of chore names
   * @param {Chore[]} chores
   * @returns {boolean}
   */
  public validateChores(chores: Chore[]): boolean {
    return chores.every(
      (chore) => chore.name.length > 0 && chore.name.length < 21
    );
  }
}

export const choresService = new ChoresService();
