import { Slide } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { TransitionProps } from "@mui/material/transitions";
import {
  forwardRef,
  FunctionComponent,
  ReactElement,
  Ref,
  useState,
} from "react";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddCategoryDialog: FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
  onComplete: (name: string) => void;
}> = ({ isOpen, onClose, onComplete }) => {
  const [categoryName, setCategoryName] = useState("");

  const handleAddCategory = () => {
    onComplete(categoryName);
    setCategoryName("");
  };

  return (
    <Dialog open={isOpen} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle>Add category</DialogTitle>
      <DialogContent>
        <DialogContentText>
          In order to better organize your chores, you can create categories.
          Please enter a name for your category.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Category name"
          value={categoryName}
          onChange={(event) => setCategoryName(event.target.value)}
          type="text"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAddCategory}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCategoryDialog;
