import { useSnackBar } from "@contexts/Snackbar.context";
import { ListProps } from "@interfaces/list.interface";
import {
  AddCircleOutline,
  Check,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  Delete,
  Edit,
} from "@mui/icons-material";
import { Box, Chip, IconButton, TextField, Typography } from "@mui/material";
import { ChangeEventHandler, FunctionComponent, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { getListItemStyles, reorder } from "./shared/functions";

const EditList: FunctionComponent<ListProps> = ({
  chores,
  categories,
  onChoresChange,
  openAddCategoryModal,
}) => {
  const { showSnackBar } = useSnackBar();
  const [selected, setSelected] = useState<string>("");
  const [editingId, setEditingId] = useState<string>("");
  const [newChoreName, setNewChoreName] = useState<string>("");

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list, delete chore
    if (!result.destination) {
      showSnackBar("Chore deleted", "success");
      return handleDelete(result.draggableId);
    }

    const reorderedChores = reorder(
      chores,
      result.source.index,
      result.destination.index
    );

    onChoresChange(reorderedChores);
  };

  const handleOnClick = (id: string) => {
    setSelected(id);
  };

  const handleDelete = (id: string) => {
    const newChores = chores.filter((chore) => chore.id !== id);
    showSnackBar("Chore deleted", "success");
    onChoresChange(newChores);
  };

  const handleEdit = (id: string) => {
    setEditingId(id);
    const currentName = chores.find((chore) => chore.id === id)?.name ?? "";
    setNewChoreName(currentName);
  };

  const handleInputOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setNewChoreName(event.target.value);
  };

  const handleEditingComplete = () => {
    // Update the list
    const newChores = chores.map((chore) =>
      chore.id === editingId ? { ...chore, name: newChoreName } : chore
    );

    // Push changes
    onChoresChange(newChores);
    showSnackBar("Chore updated", "success");

    // Reset editing state
    setNewChoreName("");
    setSelected("");
    setEditingId("");
  };

  const handleCategorySelect = (categoryId: string) => {
    if (!selected) return;
    const newChores = chores.map((chore) =>
      chore.id === selected ? { ...chore, categoryId } : chore
    );

    onChoresChange(newChores);
    showSnackBar("Chore updated", "success");
  };

  const showAddCategoryDialog = () =>
    openAddCategoryModal && openAddCategoryModal();

  const getCategoryName = (categoryId: string) => {
    const category = categories.find((category) => category.id === categoryId);
    return category?.name ?? "";
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          marginBottom: "1em",
        }}
      >
        {categories.map((category) => (
          <Chip
            key={category.id}
            label={category.name}
            onClick={() => handleCategorySelect(category.id)}
            color={
              category.id ===
              chores.find(({ id }) => id === selected)?.categoryId
                ? "primary"
                : "default"
            }
            clickable={!!selected}
          />
        ))}
        <IconButton aria-label="add" onClick={showAddCategoryDialog}>
          <AddCircleOutline color="primary" />
        </IconButton>
      </Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {chores.map((chore, index) => (
                <Draggable key={chore.id} draggableId={chore.id} index={index}>
                  {(provided, snapshot) => {
                    const isChoreSelected = selected === chore.id;
                    const isChoreCompleted = chore.completed;
                    const isEditingChore = editingId === chore.id;
                    const showDelete = isChoreSelected && !isEditingChore;
                    const hasCategory = !!chore.categoryId;
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getListItemStyles(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          isChoreSelected,
                          isChoreCompleted
                        )}
                        onClick={() => handleOnClick(chore.id)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            height: "24px",
                          }}
                        >
                          <Box sx={{ marginRight: "10px", opacity: 0.5 }}>
                            {isChoreCompleted ? (
                              <CheckBoxRounded />
                            ) : (
                              <CheckBoxOutlineBlankRounded />
                            )}
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              flex: "1",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {isEditingChore ? (
                              <form
                                onSubmit={() => handleEditingComplete()}
                                style={{
                                  flex: "1",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  autoFocus
                                  size="small"
                                  value={newChoreName}
                                  onChange={handleInputOnChange}
                                  inputProps={{ maxLength: 20 }}
                                  required
                                />
                                <Box
                                  sx={{
                                    transition: "all 0.15s ease",
                                    width: "25px",
                                    opacity: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "10px",
                                    ":hover": {
                                      color: "red",
                                      cursor: "pointer",
                                    },
                                  }}
                                  onClick={() => handleEditingComplete()}
                                >
                                  <Check />
                                </Box>
                              </form>
                            ) : (
                              chore.name
                            )}
                          </Box>
                          {hasCategory && (
                            <Box
                              sx={{
                                opacity: 1,
                                display: "flex",
                                alignItems: "center",
                                marginRight: "10px",
                                ":hover": {
                                  color: "red",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              <Chip label={getCategoryName(chore.categoryId)} />
                            </Box>
                          )}
                          {!isEditingChore && (
                            <Box
                              sx={{
                                transition: "all 0.15s ease",
                                width: isChoreSelected ? "25px" : "0px",
                                opacity: isChoreSelected ? 1 : 0,
                                display: "flex",
                                alignItems: "center",
                                ":hover": {
                                  color: "red",
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() =>
                                isChoreSelected && handleEdit(chore.id)
                              }
                            >
                              <Edit />
                            </Box>
                          )}
                          {showDelete && (
                            <Box
                              sx={{
                                transition: "all 0.15s ease",
                                width: showDelete ? "25px" : "0px",
                                opacity: showDelete ? 1 : 0,
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "10px",
                                ":hover": {
                                  color: "red",
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() =>
                                showDelete && handleDelete(chore.id)
                              }
                            >
                              <Delete />
                            </Box>
                          )}
                        </Box>
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
              {chores.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ padding: "1em", fontSize: "12pt" }}
                  >
                    Psst.. add some chores above!
                  </Typography>
                </Box>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default EditList;
