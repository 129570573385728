import { Chore } from "@/interfaces/chore.interface";
import EditList from "@components/Lists/EditList";
import { useSnackBar } from "@contexts/Snackbar.context";
import { Add, TouchApp } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import { choresService } from "@services/Chores/chores.service";
import { categoryService } from "@services/Category/category.service";
import {
  ChangeEventHandler,
  FormEventHandler,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { Category } from "@/interfaces/category.interface";
import AddCategoryDialog from "@/components/Dialogs/category.dialog";

const Edit: FunctionComponent = () => {
  const [chores, setChores] = useState<Chore[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [listId, setListId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [newChoreName, setNewChoreName] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false);

  const { showSnackBar } = useSnackBar();
  const { code } = useParams();

  // Very hacky, should not be done like this
  useEffect(() => {
    if (code) {
      try {
        setLoading(true);
        getData(Number(code));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (listId) {
      try {
        setLoading(true);
        choresService.update(
          listId,
          Number(code),
          chores,
          categories.map(({ id }) => id)
        );
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        console.log(chores);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chores, categories]);

  const handleChoreInputOnChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setNewChoreName(event.target.value);
  };

  const handleOnChoreSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const id = choresService.generateId();
    const newChore = {
      id,
      name: newChoreName,
      completed: false,
      categoryId: selectedCategory,
    };
    setChores((previous) => [...previous, newChore]);

    showSnackBar(`${newChoreName} added! 😍`, "success");
    setNewChoreName("");
  };

  const handleCategoryOnComplete = async (categoryName: string) => {
    const id = categoryService.generateId();
    const newCategory = await categoryService.create(id, categoryName);

    setCategories((previous) => [...previous, newCategory]);
    setShowCategoryModal(false);

    showSnackBar(`${categoryName} added! 😍`, "success");
  };

  const getData = async (code: number): Promise<void> => {
    const { id, chores, categoryIds } = await choresService.get(code);
    setChores(chores);
    setListId(id);

    const categories = await categoryService.get(categoryIds);
    setCategories(categories);

    choresService.subscribe(id, (newData) => {
      const { chores } = newData;
      setChores(chores);

      showSnackBar(`Chore status changed!`, "info");
    });
  };

  const handleChoresChange = (chores: Chore[]) => {
    setChores(chores);
  };

  const handleCategoriesChange = (categories: Category[]) => {
    setCategories(categories);
  };

  const handleOpenAddCategoryModal = () => {
    setShowCategoryModal(true);
  };

  const handleCodeOnClick = () => {
    navigator.clipboard.writeText(`https://bjorkdahl.dev/${code}`);
    showSnackBar("Code copied! 😍", "success");
  };

  const handleSelectedCategoryChange: (
    event: SelectChangeEvent<string>
  ) => void = (event) => {
    setSelectedCategory(event.target.value);
  };

  const categoriesExist = categories.length > 0;

  if (!listId || !code || loading) {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <AddCategoryDialog
        isOpen={showCategoryModal}
        onClose={() => setShowCategoryModal(false)}
        onComplete={handleCategoryOnComplete}
      />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            padding: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box onClick={handleCodeOnClick} sx={{ cursor: "pointer" }}>
            <ReactCodeInput fields={6} values={code.split("")} disabled />
            <Box
              sx={{
                color: pink[300],
                margin: "10px 0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Press code to copy <TouchApp sx={{ margin: "0px 4px" }} /> Share
              with friends!
            </Box>
          </Box>
          <Typography sx={{ marginTop: "2em" }} variant="h5">
            Your list
          </Typography>

          <Box
            mt={2}
            sx={{
              marginTop: "12px",
              minHeight: "50vh",
            }}
          >
            <form onSubmit={handleOnChoreSubmit}>
              <Box sx={{ display: "flex", gap: "1em", marginBottom: "1em" }}>
                <TextField
                  autoFocus
                  size="small"
                  value={newChoreName}
                  variant="outlined"
                  placeholder="Add new chore.."
                  onChange={handleChoreInputOnChange}
                  inputProps={{ maxLength: 20 }}
                  required
                />

                <Box
                  sx={{
                    transition: "all 0.15s ease",
                    width: categoriesExist ? 120 : 0,
                    opacity: categoriesExist ? 1 : 0,
                  }}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel>Category</InputLabel>
                    <Select
                      variant="outlined"
                      size="small"
                      value={selectedCategory}
                      label="Category"
                      onChange={handleSelectedCategoryChange}
                    >
                      {categories.map(({ id, name }) => (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Button variant="outlined" type="submit" startIcon={<Add />}>
                  Add
                </Button>
              </Box>
            </form>

            <EditList
              chores={chores}
              categories={categories}
              onChoresChange={handleChoresChange}
              onCategoriesChange={handleCategoriesChange}
              openAddCategoryModal={handleOpenAddCategoryModal}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Edit;
