import { FunctionComponent } from "react";
import ReactCodeInput from "react-verification-code-input";

interface CodeInputProps {
  onComplete: (code: string) => void;
}
const CodeInput: FunctionComponent<CodeInputProps> = ({ onComplete }) => {
  return <ReactCodeInput autoFocus={true} fields={6} onComplete={onComplete} />;
};

export default CodeInput;
