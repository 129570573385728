import { v4 } from "uuid";

class BaseService {
  /**
   * Generates id
   * @returns {string}
   */
  public generateId(): string {
    const id = v4();
    if (this.validateId(id)) {
      return id;
    }

    throw new Error("Generated id is invalid");
  }

  /**
   * Generates 6 digit code between 100'000 and 999'999
   * @returns {number}
   */
  public generateCode(): number {
    const code = Math.floor(Math.random() * 899999) + 100000;
    if (this.validateCode(code)) {
      return code;
    }

    throw new Error("Generated code is invalid");
  }

  /**
   * Validates length of chore names
   * @param {Chore[]} chores
   * @returns {boolean}
   */
  public validateCategoryName(name: string): boolean {
    return name.length > 0 && name.length < 21;
  }

  /**
   * Checks if code is between 100'000 and 999'999 - a six-digit number
   * TODO: Should check with database for uniqueness
   * @param {number} code
   * @returns {boolean}
   */
  private validateCode(code: number): boolean {
    return typeof code === "number" && code >= 100000 && code <= 999999;
  }

  /**
   * Checks if id is a valid v4 UUID string
   * TODO: Should check with database for uniqueness
   * @param {string} id
   * @returns {boolean}
   */
  private validateId(id: string): boolean {
    return new RegExp(
      /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/
    ).test(id);
  }
}

export default BaseService;
